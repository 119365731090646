import utilities from './utilities';


async function updateCart(form, contentType = 'text/html') {
  const response = await fetch(
      window.location,
      {
        method: 'POST',
        body: form,
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        headers: {
          "accept": contentType,
        }
      }
  );

  if ([100, 200, 201,202, 204, 205, 400].indexOf(response.status) === -1) {
    throw new Error(response.statusText);
  }

  if (contentType === 'application/json') {
    return response.json();
  } else {
    const data = await response.text();
    const p = new DOMParser();
    return p.parseFromString(data, 'text/html');
  }

}


utilities.delegate('body', 'submit', '.js-product-detail-form', async function (ev, oe) {
  oe.preventDefault();
  console.log(ev, oe);

  const submitButton = ev.target.querySelector('.js-add-to-cart');
  submitButton.disabled = true;
  submitButton.classList.add('adding');

  // let's actually add to the cart before all the other bullshit.
  const formData = new FormData(ev.target);
  const d = await updateCart(formData);

  document.querySelector('.js-show-minicart');
  document.querySelector('#mini-cart').innerHTML = d.querySelector('#mini-cart').innerHTML;
  document.querySelector('footer.js-products-with-results').style.display = 'block';
  const count = document.querySelector('#mini-cart .js-cart-count').innerText;
  document.querySelectorAll('.js-cart-count').forEach((e) => {
    e.innerHTML = count;
  });

  setTimeout(() => {
    submitButton.disabled = false;
    submitButton.classList.remove('adding');
    document.querySelector('body').classList.remove('headroom--unpinned');
    document.querySelector('body').classList.add('headroom--pinned');
    document.querySelector('.js-cart-added').classList.add("visible");
  });

  setTimeout(() => {
    document.querySelector('.js-cart-added').classList.remove("visible");
  }, 4000);

  // defer the data layer
  const elem = document.querySelector('.js-variant-btn:checked');
  setTimeout(() => {
    if (elem) {
      const purchasedItem = {
        price: elem.dataset.price,
        productName: elem.dataset.productName,
        title: elem.dataset.title,
      };

      for (const [key, value] of formData.entries()) {
        purchasedItem[key] = value;
      }

      dataLayer = window.dataLayer || [];
      dataLayer.push({ ecommerce: null });
      const dataLayerObj = {
        event: "add_to_cart",
        ecommerce: {
          currency: "NZD",
          value: purchasedItem.price,
          items: [
            {
              item_id: purchasedItem.purchasableId,
              item_name: purchasedItem.productName,
              item_variant: purchasedItem.title,
              price: purchasedItem.price,
              quantity: purchasedItem.qty
            }
          ]
        }
      };
      dataLayer.push(dataLayerObj);
      console.log(dataLayerObj);
    }
  });
});

utilities.delegate('body', 'submit', '.js-product-detail-form-complimentary', async function (ev, oe) {
  oe.preventDefault();
  // we will need to put a check if complimentary product is there then open that window

  // document.querySelector('body').classList.add('side-matches-is-open');
  // return false;

  // console.log('add_to_cart2');

  const submitButton = ev.target.querySelector('.js-side-matches-add-to-cart');
  submitButton.disabled = true;
  submitButton.classList.add('adding');
  let selectVariants = ev.target.querySelector('input[name="category"]:checked').dataset.id;
  selectVariants = selectVariants.split(',');
  let formData = new FormData(ev.target);
  for (let i = 0; i < selectVariants.length; i++) {
    let variantId = selectVariants[i];
    formData.append('purchasables['+i+'][id]', variantId);
    formData.append('purchasables['+i+'][qty]', 1);
  }
  const d = await updateCart(formData);
  document.querySelector('.js-show-minicart');
  document.querySelector('#mini-cart').innerHTML = d.querySelector('#mini-cart').innerHTML;
  document.querySelector('footer.js-products-with-results').style.display = 'block';
  const count = document.querySelector('#mini-cart .js-cart-count').innerText;
  document.querySelectorAll('.js-cart-count').forEach((e) => {
    e.innerHTML = count;
  });
  document.querySelector('body').classList.remove('side-matches-is-open');
  setTimeout(() => {
    submitButton.disabled = false;
    submitButton.classList.remove('adding');
    document.querySelector('body').classList.remove('headroom--unpinned');
    document.querySelector('body').classList.add('headroom--pinned');
    document.querySelector('.js-cart-added').classList.add("visible");
  });
  setTimeout(() => {
    document.querySelector('.js-cart-added').classList.remove("visible");
  }, 4000);
  // setTimeout(() => { document.querySelector('.js-show-minicart')?.click(); });
});

utilities.delegate('body', 'click', '.js-add-to-cart', async function (ev, oe) {
  oe.preventDefault();
  const {target} = ev;
  const {parentNode} = target;
  const form = parentNode.closest('.js-product-detail-form');
  const variant = form.querySelector('input[name="purchasableId"]:checked');
  const isComplimentary = variant.dataset.isComplimentary;
  if(isComplimentary === '1'){
    const response = await fetch(
        '/api/complimentary-check?variantId='+variant.value,
        {
          method: 'GET',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow'
        }
    );

    if ([100, 200, 201,202, 204, 205, 400].indexOf(response.status) === -1) {
      throw new Error(response.statusText);
    }
    let data = await response.text();
    const p = new DOMParser();
    data = p.parseFromString(data, 'text/html');
    document.querySelector('.js-side-matches-mini').innerHTML = new XMLSerializer().serializeToString(data);
    document.querySelector('body').classList.add('side-matches-is-open');
  }else{

    form.querySelector('.js-add-to-cart-hidden').click();
  }
  return false;
});

utilities.delegate('body', 'click', '.js-qty-btn', function (ev) {
  const {target} = ev;
  const {parentNode} = target;
  const qtyInput = parentNode.querySelector('.js-qty');
  const value =  parseInt(qtyInput.value);

  if (target.classList.contains('plus')) {
    qtyInput.value = value + 1;
  } else if (target.classList.contains('minus')) {
    qtyInput.value = Math.max(1, value - 1);
  }
});

utilities.delegate('body', 'click', '.js-show-minicart', function (ev, oe) {
  oe.preventDefault();
  document.querySelector('body').classList.add('cart-is-open');
});

utilities.delegate('body', 'click', '.js-cart-close, .js-cart-bg', function (ev, oe) {
  oe.preventDefault();
  document.querySelector('body').classList.remove('cart-is-open');
});

document.body.addEventListener('keyup', (event) => {
  if (event.keyCode === 27 && document.querySelector('body').classList.contains('cart-is-open')) {
    document.querySelector('body').classList.remove('cart-is-open');
  }
});

utilities.delegate('body', 'click', '.js-reorder-btn', async function (ev) {
  var parent = ev.target.closest('.js-reorder-main');
  var form = parent.querySelector('.js-reorder-form');
  form.submit();
});
utilities.delegate('body', 'click', '.js-cart-qty-btn', async function (ev) {
  const {target} = ev;
  const {parentNode} = target;
  const qtyInput = parentNode.querySelector('input.js-cart-qty');
  const value =  parseInt(qtyInput.value);
  if (target.classList.contains('plus')) {
    qtyInput.value = value + 1;

  } else if (target.classList.contains('minus')) {
    qtyInput.value = Math.max(1, value - 1);
  }
  if(parentNode.closest('.js-cart-item').querySelector('.js-subtotal')){
    parentNode.closest('.js-cart-item').querySelector('.js-subtotal').innerHTML = 'Loading...';
  }
  document.querySelectorAll('.js-total-cart-amount').forEach(function(e){
    e.innerHTML = 'Loading...';
  });

  let formData = new FormData(target.closest('form'));
	const data = await updateCart(formData);
  const element = document.createElement('div');
  element.innerHTML =  new XMLSerializer().serializeToString(data);
  const count = element.querySelector('#mini-cart .js-cart-count').innerHTML;

  document.querySelectorAll('.js-cart-count').forEach((e) => {
    e.innerHTML = count;
  });

  document.querySelectorAll('footer.js-products-with-results').forEach(
      (e) => {
        e.innerHTML = element.querySelector('footer.js-products-with-results').innerHTML;
    }
  );
  document.querySelectorAll('.cart-item__price.js-subtotal').forEach(
    (e) => {
        e.innerHTML = element.querySelector('.cart-item__price.js-subtotal[data-id="'+e.dataset.id+'"]').innerHTML;
    }
  );
});
utilities.delegate('body', 'click', '.js-cart-remove', async function (ev) {
  const {target} = ev;
  const {parentNode} = target;
  var orderItemContainer = target.closest('.js-cart-item');
  orderItemContainer.remove();
  var cartItems = document.querySelectorAll('.js-cart-item');
  if (cartItems.length === 0) {
      document.querySelector('.js-products-no-results').style.display = 'block';
      document.querySelector('.js-products-with-results').style.display = 'none';
      document.querySelectorAll('footer.js-products-with-results').forEach(
          (e) => {
            e.style.display = 'none';
        }
      );
      document.querySelectorAll('table.js-products-with-results').forEach(
        (e) => {
          e.style.display = 'none';
        }
      );
      document.querySelectorAll('.js-cart-footer').forEach(
        (e) => {
          e.style.display = 'none';
        }
      );

      document.querySelectorAll('.js-cart-count').forEach((e) => {
        e.innerHTML = 0;
      });
  }
  document.querySelectorAll('.js-total-cart-amount').forEach(function(e){
    e.innerHTML = 'Loading...';
  });

  let formData = new FormData(target.closest('form'));
	const data = await updateCart(formData);
  const element = document.createElement('div');
  element.innerHTML =  new XMLSerializer().serializeToString(data);
  const count = element.querySelector('#mini-cart .js-cart-count').innerHTML;

  document.querySelectorAll('.js-cart-count').forEach((e) => {
    e.innerHTML = count;
  });

  document.querySelectorAll('footer.js-products-with-results').forEach(
      (e) => {
        e.innerHTML = element.querySelector('footer.js-products-with-results').innerHTML;
    }
  );

  document.querySelectorAll('table.js-products-with-results').forEach(
      (e) => {
        if(element.querySelector('table.js-products-with-results')){
          e.innerHTML = element.querySelector('table.js-products-with-results').innerHTML;
        }else{
          e.innerHTML = "";
        }
    }
  );
});

utilities.delegate('body', 'change', '.js-cart-qty', async function (ev) {
  const {target} = ev;
  const {parentNode} = target;

  let formData = new FormData(target.closest('form'));
	const data = await updateCart(formData);
  const element = document.createElement('div');
  element.innerHTML =  new XMLSerializer().serializeToString(data);
  const count = element.querySelector('#mini-cart .js-cart-count').innerHTML;

  document.querySelectorAll('.js-cart-count').forEach((e) => {
    e.innerHTML = count;
  });

  document.querySelectorAll('footer.js-products-with-results').forEach(
      (e) => {
        e.innerHTML = element.querySelector('footer.js-products-with-results').innerHTML;
    }
  );
  document.querySelectorAll('table.js-products-with-results').forEach(
    (e) => {
        e.innerHTML = element.querySelector('table.js-products-with-results').innerHTML;
    }
  );
});

utilities.delegate('body', 'click', '.js-checkout-button--toggle-summary', async function (ev) {
  const {target} = ev;
  const {parentNode} = target;
  parentNode.classList.toggle('active');
  document.querySelector('#order-summary').classList.toggle('active');
  document.querySelector('.js-checkout-button--toggle-summary__show').classList.toggle('hidden');
  document.querySelector('.js-checkout-button--toggle-summary__hide').classList.toggle('hidden');
});

export { updateCart };
