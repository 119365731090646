export function on(targetSelector, eventName, callback) {
  document.querySelectorAll(targetSelector).forEach(element => {
    element.addEventListener(eventName, callback);
  });
}

function delegate(containerElement, eventName, targetSelector, callback) {
  document.querySelectorAll(containerElement).forEach(element => {
    element.addEventListener(eventName, (event) => {
      let e = event.target;
      do {
        if (e.matches(targetSelector)) {
          callback({target: e}, event);
          return;
        }
      } while ((e = e.parentNode) && element.contains(e));
    });
  });
}

function zap(targetSelector, className, action) {
  document.querySelectorAll(targetSelector).forEach((element) => element.classList[action](className));
}

function remove(targetSelector, className) {
  zap(targetSelector, className, 'remove');
}

function add(targetSelector, className) {
  zap(targetSelector, className, 'add');
}

function toggle(targetSelector, className) {
  zap(targetSelector, className, 'toggle');
}

export default {
  toggle,
  add,
  remove,
  on,
  delegate,
  zap
}
